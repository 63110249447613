<li
  class="flex cursor-pointer items-center justify-start px-4 py-2 text-gray-700 hover:bg-gray-100"
>
  @if (isMultiSelect) {
    <app-checkbox
      [checked]="checked"
      class="pointer-events-none"
    ></app-checkbox>
  }
  <ng-content></ng-content>
</li>
