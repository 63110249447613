import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  Optional,
  QueryList,
  Self,
  ViewChild,
} from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ClickedOutsideDirective } from '@shared/_components/_directives/clicked-outside.directive';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { noop } from 'rxjs';
import { TypeofPipe } from '@shared/_pipes/typeof.pipe';
import { DropdownItemComponent } from '@shared/_components/_input-components/dropdown/dropdown-item/dropdown-item.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [AngularSvgIconModule, ClickedOutsideDirective, TypeofPipe, NgClass],
  templateUrl: './dropdown.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements ControlValueAccessor {
  @ContentChildren(DropdownItemComponent)
  items!: QueryList<DropdownItemComponent>;
  @ViewChild('scrollContainer')
  scrollContainer?: ElementRef;
  @Input() dropdownPlaceholder = '';
  @Input() label?: string;
  @Input() required = false;
  @Input() disabled = false;
  @Input() isMultiSelect = false;
  @Input({ required: true, alias: 'formControl' })
  requireFormControl!: FormControl;

  showDropdown = false;
  onChange: (value: string | number | undefined) => void = noop;
  onTouch: () => void = noop;

  constructor(
    @Self()
    @Optional()
    public formControl: NgControl,
  ) {
    if (this.formControl) {
      this.formControl.valueAccessor = this;
    }
  }

  registerOnChange(fn: (value: string | number | undefined) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {}
}
