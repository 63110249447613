import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';

let inputId = 100;

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [FormsModule, AngularSvgIconModule],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() label = '';
  @Input() checked = false;
  @Input() tabindex = 0;
  @Input() disabled = false;

  uniqueId = `input-${inputId++}`;

  constructor(private cd: ChangeDetectorRef) {}

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(checked: boolean) {
    this.checked = checked;
    this.cd.detectChanges();
  }

  onModelChange(e: boolean) {
    this.checked = e;
    this.onChange(e);
  }
}
