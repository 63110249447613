import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CheckboxComponent } from '@shared/_components/_input-components/checkbox/checkbox.component';

@Component({
  selector: 'app-dropdown-item',
  standalone: true,
  imports: [CheckboxComponent],
  templateUrl: './dropdown-item.component.html',
  styles: [],
  host: {},
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownItemComponent {
  @Input()
  isMultiSelect = false;
  @Input() checked = false;
}
