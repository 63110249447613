<div class="relative flex items-center gap-2 text-sm font-medium">
  <input
    type="checkbox"
    [id]="uniqueId"
    data-testid="checkbox"
    class="checked:!bg-ds-blue-700 checked:border-ds-blue-700 hover:bg-ds-blue-100 disabled:bg-ds-blue-400 disabled:border-ds-blue-400 peer relative h-4 w-4 shrink-0 appearance-none rounded-sm border border-gray-200 bg-white outline-offset-4"
    [ngModel]="checked"
    [disabled]="disabled"
    (ngModelChange)="onModelChange($event)"
    [tabindex]="tabindex"
  />
  @if (label) {
    <label data-testid="label" [for]="uniqueId">{{ label }}</label>
  }
  <svg-icon
    class="pointer-events-none absolute h-4 w-4 text-gray-900 opacity-0 transition-all peer-checked:!opacity-100 peer-hover:opacity-40"
    src="/assets/icons/tick.svg"
  ></svg-icon>
</div>
